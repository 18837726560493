<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left" v-if="permissionEnabled('users', 'edit') || permissionEnabled('users', 'delete')">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :id="`action-${l.type}`" :class="l.color ? l.color : 'has-text-grey-light'" v-show="showButtons(l)" @click="handleClick(l, id)">
				<svg-icon :icon="l.icon"></svg-icon>
				<span>{{ $t(l.name) }}</span>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: true
		},
		role: {
			type: String,
			required: true
		},
		active: {
			type: [Boolean, Number],
			required: true
		},
		name: {
			type: String,
			required: true
		},
		email: {
			type: String,
			required: true
		},
		delete: {
			type: Boolean,
			default: false
		},
		items: {
			type: Array,
			required: false,
			default: () => {
				return [
					{
						id: 1,
						name: 'labels.edit',
						icon: 'edit',
						type: 'edit'
					},
					// {
					// 	id: 2,
					// 	name: 'labels.restore',
					// 	icon: 'restore',
					// 	color: 'has-text-info',
					// 	type: 'restore'
					// },
					{
						id: 3,
						name: 'labels.disable',
						icon: 'restore',
						color: 'has-text-info disable',
						type: 'disable'
					},
					{
						id: 4,
						name: 'labels.activate',
						icon: 'restore',
						color: 'has-text-info',
						type: 'activate'
					},
					{
						id: 5,
						name: 'labels.delete',
						icon: 'trash',
						color: 'has-text-danger',
						type: 'delete'
					}
				]
			}
		}
	},
	methods: {
		handleClick(el, id) {
			const type = el.type
			if (type === 'edit') {
				eventHub.$emit('edit-modal-users', {
					id,
					root: this.role
				})
			}  else if (type === 'disable') {
				eventHub.$emit('disable-users', {
					id
				})
			} else if (type === 'activate') {
				eventHub.$emit('restore-users', {
					id
				})
			} else if (type === 'delete') {
				eventHub.$emit('delete-users', {
					id
				})
			}
		},
		showButtons(item) {
			let show = true

			if (this.permissionEnabled('users', item.type)) {
				show = true
			}

			if (item.type == 'restore' && !this.delete) {
				show = false
			}

			if (item.type == 'activate' && this.active || item.type == 'disable' && !this.active) {
				show = false
			}

			if (this.id === 1 || this.id === 2 && item.type == 'delete') {
				show = false
			}

			return show
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
